<template>
  <div width="100%">
    <div>
      <div>Breadcrumb:</div>
      <div>
        <v-breadcrumbs style="display: inline" :items="items"></v-breadcrumbs>
      </div>
    </div>
    <div>
      <div>General link:</div>
      <div style="font-style:italic;">
        Where you need guidance on making a report or have questions regarding child safety and
        wellbeing, contact one of the School/ College's
        <a href="https://www.google.com">Child Protection officers</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '#'
      },
      {
        text: 'Child Safeguarding Program',
        disabled: false,
        href: '#'
      },
      {
        text: 'Executive Summary',
        disabled: true,
        href: '#'
      }
    ]
  })
};
</script>
