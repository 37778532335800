const files = require.context('.', true, /store\.js$/);
const modules = {};

files.keys().forEach(key => {
  let moduleName = key.replace(/(\.\/|\.js)/g, ''); // Remove dot in file name
  moduleName = moduleName.replace(/\/store$/g, ''); // Remove /store in file name
  modules[moduleName] = files(key).default;
});

export default modules;
