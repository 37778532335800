<template>
  <cs-app
    :showHeader="isLoggedIn"
    :sideNavWidth="sideNavWidth"
    customerLogoUri="https://s3-us-west-2.amazonaws.com/develop.contentengine.compliengine.com/client-assets/212/db2d3267f4d3c75aaedbd8351433c04e-small.png"
    customerName="Complispace"
    :feedbackBtn="feedbackBtn"
    @feedback-btn-click="onFeedbackBtn"
    :helpBtn="helpBtn"
    :searchBtn="searchBtn"
    @search-btn-click="onSearchBtn"
    :updateBtn="updateBtn"
    :viewBtn="viewBtn"
    @view-btn-click="onViewBtn"
    :version="version"
  >
    <template v-slot:header-menu>
      <slot-indicator
        v-if="headerType === 0"
        slot-name="Menu"
        width="auto"
        height="auto"
      ></slot-indicator>
      <v-btn
        v-if="headerType === 1"
        elevation="0"
        class="cs-button primary"
        height="40px"
        :ripple="false"
        >Create
      </v-btn>
    </template>
    <template v-slot:header-left>
      <slot-indicator
        v-if="headerType === 0"
        slot-name="Call to action"
        width="auto"
        height="auto"
      ></slot-indicator>
    </template>
    <template v-if="headerType !== 2" v-slot:header-right>
      <v-select
        id="header-style-select"
        dark
        dense
        flat
        :items="[
          { text: 'Pink', value: 0 },
          { text: 'Ce Theme', value: 1 }
        ]"
        v-model="currentTheme"
        style="z-index: 20; max-width: 200px; align-self: center"
        class="header-style-select white--text"
        hide-details
        @change="onChangeTheme"
      ></v-select>
    </template>
    <template v-if="headerType !== 2" v-slot:header-right-2>
      <v-select
        id="header-style-select"
        dark
        dense
        flat
        :items="[
          { text: 'Sample 1', value: 0 },
          { text: 'Sample 2', value: 1 },
          { text: 'Sample 3', value: 2 }
        ]"
        :value="headerType"
        style="z-index: 20; max-width: 200px; align-self: center"
        class="header-style-select white--text"
        hide-details
        @change="onHeaderStyleChange"
      ></v-select>
    </template>
    <template v-slot:header-help-menu>
      <slot-indicator slot-name="Call to action" width="auto" height="auto"></slot-indicator>
    </template>

    <template v-if="headerType === 1" v-slot:header-settings-menu>
      <slot-indicator slot-name="Call to action" width="auto" height="auto"></slot-indicator>
    </template>

    <template v-slot:side-nav>
      <slot-indicator slot-name="Call to action" width="auto" height="20rem">
        <template v-slot:content>
          <v-container fluid>
            <v-row align="center">
              <v-col class="d-flex">
                <v-select
                  :items="items"
                  dense
                  solo
                  label="Select View Type"
                  v-model="viewType"
                ></v-select>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col md class="d-flex">
                <v-btn width="100%" @click.stop.prevent="onHideSideNav">Hide Side Nav</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container style="position: absolute; bottom: 0">
            <v-row>
              <v-col class="col-12 version">cs-design-systems v{{ csDesignSystemVersion }}</v-col>
            </v-row>
          </v-container>
        </template>
      </slot-indicator>
    </template>

    <template v-slot:router-view>
      <v-slide-y-transition>
        <router-view></router-view>
      </v-slide-y-transition>
    </template>

    <template v-slot:footer>
      <div class="content">
        <v-layout align-center justify-center column fill-height>
          <v-img :src="csLogo" class="cs-logo"> </v-img>
          <span>
            &copy;{{ copyRightYearRange }} CompliSpace (v{{ version }}). All rights reserved.
          </span>
        </v-layout>
      </div>
    </template>
  </cs-app>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import tinykeys from 'tinykeys';
import definitions from '@complispace/cs-apex-definitions';
import csLogo from './assets/complispace-logo.svg';
import { version, dependencies } from '../package.json';
import * as types from './store/mutationTypes';

export default {
  name: 'App',

  extends: CSBase,

  data() {
    return {
      csLogo,
      customerLogo: `https://s3-us-west-2.amazonaws.com/develop.contentengine.compliengine.com/client-assets/212/db2d3267f4d3c75aaedbd8351433c04e-small.png`,
      items: [
        {
          text: 'single-view',
          value: 'single-view'
        },
        {
          text: 'wide-nav',
          value: 'wide-nav'
        },
        {
          text: 'narrow-nav',
          value: 'narrow-nav'
        },
        {
          text: 'Apex Definitions',
          value: 'apex-definitions'
        },
        {
          text: 'Form Controls',
          value: 'form-controls'
        },
        {
          text: 'HelixifyVuetifyDefinitions',
          value: 'helixify-vuetify-definitions'
        }
      ],
      selectedView: 'single-view',
      hideSideNav: false,
      headerType: 0,
      viewActive: false,
      currentTheme: 0
    };
  },

  mounted() {
    document.title = this.$store.getters['appearance/viewTitle'] || 'cs-design-system-docs';
    tinykeys(window, {
      'Control+Shift+e': () => {
        this.$store.commit(
          types.ENGINEERING_SET_TOGGLE,
          this.$store.getters['engineering/enabled']
        );
      }
    });
  },

  created() {
    this.setViewTitle('CS Design System Documentations');
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'cs-session/isLoggedIn'
    }),

    ...mapState({
      viewTypeStore: 'view-type'
    }),

    viewType: {
      get() {
        return this.viewTypeStore.viewType;
      },

      set(value) {
        this.setPageViewType(value);
        this.changeRoute(value);
      }
    },

    feedbackBtn() {
      return {
        id: 'id-for-feedback',
        toolTip: 'put your feedback here'
      };
    },

    searchBtn() {
      return {
        id: 'id-for-search',
        toolTip: 'put your search here'
      };
    },

    updateBtn() {
      if (this.headerType === 0) {
        return null;
      }
      return {
        id: 'check-for-updates',
        toolTip: 'Check for Updates',
        action: this.onUpdateBtn
      };
    },

    viewBtn() {
      return {
        id: 'id-for-view',
        toolTip: 'Administrator View',
        active: this.viewActive
      };
    },

    helpBtn() {
      return {
        id: 'id-for-help',
        href: 'https://www.google.com',
        toolTip: 'Navigate to google for help'
      };
    },

    sideNavWidth() {
      return this.isLoggedIn && !this.hideSideNav ? 300 : 0;
    },

    version() {
      return version;
    },

    copyRightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    },
    csDesignSystemVersion() {
      return dependencies['@complispace/cs-design-system'];
    }
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),

    onFeedbackBtn() {
      // eslint-disable-next-line no-alert
      alert('help button action executed!');
    },

    onSearchBtn() {
      // eslint-disable-next-line no-alert
      alert('search button action executed!');
    },

    onUpdateBtn() {
      // eslint-disable-next-line no-alert
      alert('update button action executed!');
    },

    onViewBtn() {
      this.viewActive = !this.viewActive;
      if (this.viewActive) {
        this.headerType = 1;
      } else {
        this.headerType = 0;
      }
    },
    onHeaderStyleChange(value) {
      this.headerType = value;
    },

    onChangeTheme(useCeTheme) {
      let theme = definitions.helixifyVuetifyDefinitions.theme.themes.light;
      if (useCeTheme) {
        theme = definitions.helixifyVuetifyCeDefinitions.theme.themes.light;
      }
      this.$vuetify.theme.themes.light = theme;
    },

    changeRoute(viewType) {
      switch (viewType) {
        case 'single-view':
          if (this.$route.path === '/') {
            this.$router.go();
          } else {
            this.$router.push('/');
          }
          break;
        case 'wide-nav':
          if (this.$route.path === '/wide-nav') {
            this.$router.go();
          } else {
            this.$router.push('/wide-nav');
          }
          break;
        case 'narrow-nav':
          if (this.$route.path === '/narrow-nav') {
            this.$router.go();
          } else {
            this.$router.push('/narrow-nav');
          }
          break;
        case 'apex-definitions':
          this.onShowApexDefinitions();
          break;
        case 'form-controls':
          this.onShowCsFormControls();
          break;
        case 'helixify-vuetify-definitions':
          this.onShowHelixifyVuetifyDefinitions();
          break;
        default:
          if (this.$route.path === '/') {
            this.$router.go();
          } else {
            this.$router.push('/');
          }
      }
    },

    onHideSideNav() {
      this.hideSideNav = true;
    },
    onShowApexDefinitions() {
      if (this.$route.path === '/apex-definitions') {
        this.$router.go();
      } else {
        this.$router.push('/apex-definitions');
      }
    },

    onShowCsFormControls() {
      if (this.$route.path === '/form-controls') {
        this.$router.go();
      } else {
        this.$router.push('/form-controls');
      }
    },

    onShowHelixifyVuetifyDefinitions() {
      if (this.$route.path === '/helixify-vuetify-definitions') {
        this.$router.go();
      } else {
        this.$router.push('/helixify-vuetify-definitions');
      }
    }
  }
};
</script>

<style>
.v-footer.theme--light.v-sheet {
  background-color: white;
}

.v-footer {
  justify-content: space-around;
}
</style>
<style scoped>
.cs-logo {
  width: 200px;
  height: 40px;
}
.version {
  font-size: 9pt;
  padding-left: 18px;
  color: black;
}
.cs-button {
  padding-right: 12px !important;
  padding-left: 12px !important;
  border-radius: 8px;
  text-transform: none !important;
}
</style>
