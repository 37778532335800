<template>
  <cs-view single-view>
    <template v-slot:content>
      <v-expansion-panels accordion :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header class="title-1">Links</v-expansion-panel-header>
          <v-expansion-panel-content>
            <cs-links-test />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="title-1"
            >CSIllustrationSelector</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <cs-illustration-selector-test />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </cs-view>
</template>
<script>
import { mapMutations } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import * as types from '../store/mutationTypes';
import CSIllustrationSelectorTest from '../components/CSIllustrationSelectorTest';
import CSLinksTest from '../components/CSLinksTest';

export default {
  name: 'FormControls',

  components: {
    'cs-illustration-selector-test': CSIllustrationSelectorTest,
    'cs-links-test': CSLinksTest
  },

  extends: CSBase,

  data() {
    return {};
  },
  async mounted() {
    this.setPageViewType('form-controls');
  },
  computed: {},
  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    })
  }
};
</script>

<style scoped></style>
