<template>
  <v-card class="ma-0 pa-2">
    <v-card-subtitle>{{ title }}</v-card-subtitle>
    <div v-for="(value, name) in colorDefinitions" :key="name" class="colorDefinition">
      <div v-if="hasColorName(value)" class="colorName">
        {{ name }}
      </div>
      <v-chip large class="ma-2" label outlined pill color="grey">
        <div class="pt-2 pb-0" style="min-width: 65px">
          <div class="colorText">
            {{ displayName(value, name) }}
          </div>
          <span class="colorCode">{{ value.base }}</span>
        </div>
        <div class="colorPill" :style="{ backgroundColor: value.base }"></div>
      </v-chip>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: 'ColorPalette',
  props: {
    title: { type: String, require: true },
    colorDefinitions: { type: Object, require: true }
  },
  data() {
    return { colors: {} };
  },
  methods: {
    hasColorName(colorDef) {
      try {
        if (colorDef.colorName) {
          return true;
        }
      } catch (er) {
        return false;
      }
      return false;
    },
    displayName(colorDef, defName) {
      if (this.hasColorName(colorDef)) {
        return colorDef.colorName;
      }
      return defName;
    }
  }
};
</script>
<style scoped>
.colorDefinition {
  display: inline-flex;
  background-color: white;
  min-width: 100px;
  align-items: center;
}
.colorPill {
  width: 40px;
  height: 40px;
  font-size: 10pt;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  border-radius: 2px;
}
.colorName {
  min-width: 170px;
  color: black;
  font-size: 10pt;
  padding-left: 10px;
}
.colorText {
  color: black;
  font-size: 10pt;
  line-height: 0.9;
}
.colorCode {
  font-size: 8pt;
  line-height: 0.9;
}
</style>
