/*
    Mutation naming convention.

    UPDATE_ - Update the complete module store.
    SET_ - Update a single property in the module store.
    RESET_ - Clear the module store back to its original values.

    NOTE: We could of reused mutation names across all modules
    e.g. RESET, UPDATE, ADD to avoid types like SESSION_RESET_SESSION,
    but I think in the long run it is better to explicity state each mutation than be generic.
*/
export const SET_TOGGLE = 'setToggle';
export const SET_PAGE_VIEW_TYPE = 'setPageViewType';
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const ENGINEERING_SET_TOGGLE = `engineering/${SET_TOGGLE}`;
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const VIEW_TYPE_SET_PAGE_VIEW_TYPE = `view-type/${SET_PAGE_VIEW_TYPE}`;
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
