<template>
  <div width="100%">
    <div>
      <div>Selected Illustration:</div>
      <div class="my-3" style="display: flex; align-items: center; justify-content: center">
        <div class="mx-3"><img height="80px" :src="encodeURI(selectedImg)" alt="" /></div>
        <div class="mb-3">{{ selectedImg }}</div>
      </div>
      <v-btn id="btn-show-dialog" class="primary" @click="onShowFormDialogClick"
        >Show in Form</v-btn
      >
      <v-btn id="btn-reset-illustration" class="ml-2 primary" @click="onResetIllustration"
        >Reset</v-btn
      >
      <cs-illustration-selector
        id="illustration-selector"
        v-model="selectedImg"
        path=""
        @change="onIllustrationChange"
        class="mt-2"
      ></cs-illustration-selector>
      <cs-illustration-selector
        id="illustration-selector3"
        v-model="selectedImg"
        path="Users"
        @change="onIllustrationChange"
        class="mt-2"
      ></cs-illustration-selector>
    </div>
    <cs-dialog id="form-dialog-1" v-model="showFormDialog" width="800px">
      <template v-slot:dialog-title>
        <span>CSIllustration Selector in Form</span>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="showFormDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <template v-slot:dialog-body>
        <div class="pa-2">
          <cs-illustration-selector
            v-model="dlgSelectedImg"
            path=""
            label="test image selector"
          ></cs-illustration-selector>
        </div>
      </template>
      <template v-slot:dialog-actions>
        <v-btn @click="showFormDialog = false">Cancel</v-btn>
        <v-btn @click="onDlgOkClick">Ok</v-btn>
      </template>
    </cs-dialog>
  </div>
</template>

<script>
import { CSDialog, CSIllustrationSelector } from '@complispace/cs-design-system';

export default {
  name: 'CSIllustrationSelectorTest',

  components: {
    'cs-illustration-selector': CSIllustrationSelector,
    'cs-dialog': CSDialog
  },

  data() {
    return {
      showFormDialog: false,
      selectedImg: '/illustrations/Users/Learners.svg',
      dlgSelectedImg: '/illustrations/Users/Learners.svg'
    };
  },
  created() {},
  methods: {
    onIllustrationChange(newImg) {
      // log for for demo purpose
      console.log('onIllustrationChange', newImg);
    },
    onShowFormDialogClick() {
      this.dlgSelectedImg = this.selectedImg;
      this.showFormDialog = true;
    },
    onResetIllustration() {
      this.selectedImg = '';
    },
    onDlgOkClick() {
      this.showFormDialog = false;
      this.selectedImg = this.dlgSelectedImg;
      this.dlgSelectedImg = '';
    }
  }
};
</script>
<style scoped>
.buttons-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.colorDefinition {
  border: 1px solid gray;
  width: 100%;
}
.colorDesc {
  min-width: 50px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.colorPill {
  width: 100px;
  height: 50px;
  font-size: 10pt;
  border: 1px solid gray;
}
</style>
