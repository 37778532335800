<template>
  <cs-view single-view>
    <template v-slot:content>
      <v-row class="ma-2">
        <v-col class="pa-0">
          <v-card class="ma-0 pa-2">
            <v-card-title>Default Theme</v-card-title>
            <v-card-text class="code">
              <pre>{{ defaultTheme }}</pre>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pa-0">
          <v-card class="ma-0 pa-2">
            <v-card-title>CE Theme</v-card-title>
            <v-card-text class="code">
              <pre>{{ ceTheme }}</pre>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </cs-view>
</template>
<script>
import { mapMutations } from 'vuex';
import { CSBase, vuetifyBuilder } from '@complispace/cs-design-system';
import * as types from '../store/mutationTypes';

export default {
  name: 'HelixifyVuetifyDefinitions',

  extends: CSBase,

  data() {
    return {};
  },

  async mounted() {
    this.setPageViewType('helixify-vuetify-definitions');
  },

  computed: {
    defaultTheme() {
      return JSON.stringify(vuetifyBuilder({}).getHelixifyVuetifyDefinitions(), undefined, 2);
    },

    ceTheme() {
      return JSON.stringify(
        vuetifyBuilder({ themeName: 'ce' }).getHelixifyVuetifyDefinitions(),
        undefined,
        2
      );
    }
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    })
  }
};
</script>

<style scoped></style>
