var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cs-app',{attrs:{"showHeader":_vm.isLoggedIn,"sideNavWidth":_vm.sideNavWidth,"customerLogoUri":"https://s3-us-west-2.amazonaws.com/develop.contentengine.compliengine.com/client-assets/212/db2d3267f4d3c75aaedbd8351433c04e-small.png","customerName":"Complispace","feedbackBtn":_vm.feedbackBtn,"helpBtn":_vm.helpBtn,"searchBtn":_vm.searchBtn,"updateBtn":_vm.updateBtn,"viewBtn":_vm.viewBtn,"version":_vm.version},on:{"feedback-btn-click":_vm.onFeedbackBtn,"search-btn-click":_vm.onSearchBtn,"view-btn-click":_vm.onViewBtn},scopedSlots:_vm._u([{key:"header-menu",fn:function(){return [(_vm.headerType === 0)?_c('slot-indicator',{attrs:{"slot-name":"Menu","width":"auto","height":"auto"}}):_vm._e(),(_vm.headerType === 1)?_c('v-btn',{staticClass:"cs-button primary",attrs:{"elevation":"0","height":"40px","ripple":false}},[_vm._v("Create ")]):_vm._e()]},proxy:true},{key:"header-left",fn:function(){return [(_vm.headerType === 0)?_c('slot-indicator',{attrs:{"slot-name":"Call to action","width":"auto","height":"auto"}}):_vm._e()]},proxy:true},(_vm.headerType !== 2)?{key:"header-right",fn:function(){return [_c('v-select',{staticClass:"header-style-select white--text",staticStyle:{"z-index":"20","max-width":"200px","align-self":"center"},attrs:{"id":"header-style-select","dark":"","dense":"","flat":"","items":[
        { text: 'Pink', value: 0 },
        { text: 'Ce Theme', value: 1 }
      ],"hide-details":""},on:{"change":_vm.onChangeTheme},model:{value:(_vm.currentTheme),callback:function ($$v) {_vm.currentTheme=$$v},expression:"currentTheme"}})]},proxy:true}:null,(_vm.headerType !== 2)?{key:"header-right-2",fn:function(){return [_c('v-select',{staticClass:"header-style-select white--text",staticStyle:{"z-index":"20","max-width":"200px","align-self":"center"},attrs:{"id":"header-style-select","dark":"","dense":"","flat":"","items":[
        { text: 'Sample 1', value: 0 },
        { text: 'Sample 2', value: 1 },
        { text: 'Sample 3', value: 2 }
      ],"value":_vm.headerType,"hide-details":""},on:{"change":_vm.onHeaderStyleChange}})]},proxy:true}:null,{key:"header-help-menu",fn:function(){return [_c('slot-indicator',{attrs:{"slot-name":"Call to action","width":"auto","height":"auto"}})]},proxy:true},(_vm.headerType === 1)?{key:"header-settings-menu",fn:function(){return [_c('slot-indicator',{attrs:{"slot-name":"Call to action","width":"auto","height":"auto"}})]},proxy:true}:null,{key:"side-nav",fn:function(){return [_c('slot-indicator',{attrs:{"slot-name":"Call to action","width":"auto","height":"20rem"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex"},[_c('v-select',{attrs:{"items":_vm.items,"dense":"","solo":"","label":"Select View Type"},model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"md":""}},[_c('v-btn',{attrs:{"width":"100%"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onHideSideNav.apply(null, arguments)}}},[_vm._v("Hide Side Nav")])],1)],1)],1),_c('v-container',{staticStyle:{"position":"absolute","bottom":"0"}},[_c('v-row',[_c('v-col',{staticClass:"col-12 version"},[_vm._v("cs-design-systems v"+_vm._s(_vm.csDesignSystemVersion))])],1)],1)]},proxy:true}])})]},proxy:true},{key:"router-view",fn:function(){return [_c('v-slide-y-transition',[_c('router-view')],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"content"},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c('v-img',{staticClass:"cs-logo",attrs:{"src":_vm.csLogo}}),_c('span',[_vm._v(" ©"+_vm._s(_vm.copyRightYearRange)+" CompliSpace (v"+_vm._s(_vm.version)+"). All rights reserved. ")])],1)],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }