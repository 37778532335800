<template>
  <cs-view single-view>
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col class="col-12 version">cs-apex-definitions v{{ version }}</v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0">
            <color-palette title="Colors" :colorDefinitions="colors"></color-palette>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0">
            <v-card class="ma-0 pa-2">
              <v-card-title>Complispace Colors</v-card-title>
              <color-palette
                v-for="(themeValue, name) in vuetifyThemes"
                :key="name"
                :title="'Theme: ' + name"
                :colorDefinitions="getColorDefinitions(themeValue)"
              ></color-palette>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0"></v-col>
        </v-row>
      </v-container>
    </template>
  </cs-view>
</template>

<script>
import { mapMutations } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import definitions from '@complispace/cs-apex-definitions';
import * as types from '../store/mutationTypes';
import ColorPalette from '../components/ColorPalette';
import { dependencies } from '../../package.json';

export default {
  name: 'ApexDefinitions',

  components: {
    'color-palette': ColorPalette
  },

  extends: CSBase,

  data() {
    return {
      colors: definitions.helixifyColors,
      vuetifyThemes: definitions.helixifyVuetifyDefinitions.theme.themes
    };
  },
  async created() {
    this.colorsMap = this.createColorMap();
    this.vuetifyThemes = this.getVuetifyThemes();
  },
  async mounted() {
    this.setPageViewType('apex-definitions');
  },
  computed: {
    version() {
      return dependencies['@complispace/cs-apex-definitions'];
    }
  },
  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),
    createColorMap() {
      const { helixifyColors } = definitions;
      const map = new Map();
      Object.keys(helixifyColors).forEach(colorName => {
        const colorDef = helixifyColors[colorName];
        map.set(colorDef.base, { colorName, base: colorDef.base });
      });
      return map;
    },
    getNamedColor(colorCode) {
      const colorDef = this.colorsMap.get(colorCode);
      return { colorName: colorDef.colorName, base: colorDef.base } || {};
    },
    getColorDefinitions(themeColors) {
      const namedColors = {};
      if (!themeColors || !this.colorsMap) {
        return namedColors;
      }
      Object.keys(themeColors).forEach(key => {
        const colorDef = themeColors[key];
        Object.keys(colorDef).forEach(c => {
          let colorName = key;
          if (c !== 'base') {
            colorName = `${key}-${c}`;
          }
          namedColors[colorName] = this.getNamedColor(colorDef[c]);
        });
      });

      return namedColors;
    },
    getVuetifyThemes() {
      const { themes } = definitions.helixifyVuetifyDefinitions.theme;
      const ceThemes = definitions.helixifyVuetifyCeDefinitions.theme.themes;
      Object.keys(ceThemes).forEach(theme => {
        themes[`ce-${theme}`] = ceThemes[theme];
      });
      return themes;
    }
  }
};
</script>

<style scoped>
.version {
  font-size: 9pt;
  padding-left: 18px;
}
</style>
